import React from 'react'

const HowItWorks = () => {
    return (
        <div>
            
            <section id="features" className="features home_feature_top">
                <div className="aos-init aos-animate redtie_statistics" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-6">
                            <img src={require("../assets/img/how-it-works/redtie-text-messaging-statistics-1.png")} className="img-fluid desk_show" alt="" />
                        </div>
                        <div className="col-lg-6 mt-lg-0 d-flex">
                            <div className="me-2 ms-2 align-self-center gy-4 how-it-works clr_black">
                                <h3 className="mob_txt_cntr pb-3">Mind-Blowing Text Messaging Statistics</h3>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>3.8 billion is the number of people that own smartphones in 2021.</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>75% of clients want to receive offers via SMS.</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>The CTR rate for offer messages is higher by 9.18% compared to any other digital channel.</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>SMS has a 98% open rate compared to email marketing.</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check" />
                                    <h3>60% of customers read texts within 1-5 minutes after receiving them.</h3>
                                </div>
                                <div className="feature-box d-flex align-items-center mb-4">
                                    <i className="bi bi-check" />
                                    <h3>As of 2021, 67 million Americans redeem coupons via mobile phones.</h3>
                                </div>
                                <div className="row mt-4 mb-4">
                                    <div className="col-sm-12 aos-init aos-animate" data-aos="fade-up" data-aos-delay={700}>
                                        <img src={require("../assets/img/how-it-works/redtie-text-messaging-statistics.png")} className="img-fluid mob_show" alt="" />
                                        <div data-aos="fade-up" data-aos-delay={600} className="aos-init aos-animate">
                                            <div className="text-center text-lg-start mt-3">
                                                <a href="https://application.redtie.co/"  className="btn-get-started-section d-inline-flex">
                                                    <span>Sign Up</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )
}

export default HowItWorks