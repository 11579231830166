import React from "react";
const Banner = () => {
    return (
        <section id="hero" className="hero d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 d-flex flex-column justify-content-center text-center mt-5">
                        <h1 data-aos="fade-up">Business Messaging Gateway for <span className="bg_hig_lig_h">Everything,</span> <br />Just like email </h1>
                        <p className="mt-3"> The robust and secure text messaging solution for your Business </p>
                        {/* <p className="mt-4"> Easy, affordable and a reliable solution in market for <span className="bg_hig_lig_h">Business Texting, Marketing , File Sharing</span>  and for staying close with your <span className="bg_hig_lig_h">leads</span> and customers.</p> */}
                        <p className="mt-3 f_sz18">Business Texting <span className="bg_hig_lig_h">|</span> Text Marketing <span className="bg_hig_lig_h">|</span> File Sharing <span className="bg_hig_lig_h">|</span> Lead Generation <span className="bg_hig_lig_h">|</span> Customer Engagement <span className="bg_hig_lig_h">|</span> Announcements <span className="bg_hig_lig_h">|</span> Customer Support <span className="bg_hig_lig_h">|</span> Sales </p>
                        {/* <p className="mt-4"> Easy, affordable and the reliable solution in market  for <span className="bg_hig_lig_h">text marketing campaigns</span> or to engage in one to one conversations with your customers </p> */}
                        <div data-aos="fade-up" data-aos-delay={600} className="mb-5">
                            <div className="text-center flex-row mt-4 justify-content-center align-items-center mb-2">
                                <a href="https://application.redtie.co/" className="btn-get-started-section d-inline-flex mt-2">
                                    <span>Get Started</span>
                                </a>                               

                                {/* <a href="https://application.redtie.co/" className="btn-get-started-section d-inline-flex ms-3 mt-2">
                                    <span>Book a Demo</span>
                                </a> */}

                                {/* <p className="mt-4 f_sz18">100 % Refund if not satisfied.</p> */}
                            </div>
                        </div>
                    </div>
                   <div className="col-lg-12 mt-0 mb-5">
                        <div className='text-center justify-content-center align-items-center'>
                            <img src={require("../assets/img/redtie-message-board.png")} className="img-fluid msg_brd_img" alt="redtie-message-board"/>
                        </div>
                    </div>
                </div>
            </div>            
        </section>
        
        

    );
}
export default Banner;