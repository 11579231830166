import React from 'react'

const TwoWayMessage = () => {
    return (
        <section id="features" className="features mt-5">
            
            <div className="container aos-init aos-animate" data-aos="fade-up">
            <div className="row">

            <header className="section-header mob_btm_0">
                    <h2 className="">See How Businesses Can Use Two-Way Messaging</h2>
                    <p className="">Redtie is the smart way to connect with your customers. With the features below, you can have better engagement with them.</p>
                </header>
        <div className="col-lg-6">
        <img src={require("../assets/img/redtie-mob-app-screen.png")} className="img-fluid p-4 two-Way-mob-img" alt="" />

        </div>
        <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
          <div className="row align-self-center gy-4">
            <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay={200}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Marketing</h3>
              </div>
            </div>
            <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay={300}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Customer  Support</h3>
              </div>
            </div>
            <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay={400}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Sales</h3>
              </div>
            </div>
            <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay={500}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Reminders</h3>
              </div>
            </div>
            <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay={600}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Customer  Service</h3>
              </div>
            </div>
            <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay={700}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Customer  Engagement</h3>
              </div>
            </div>
            <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay={600}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Announcements</h3>
              </div>
            </div>
            <div className="col-md-6 aos-init aos-animate" data-aos="zoom-out" data-aos-delay={700}>
              <div className="feature-box d-flex align-items-center">
                <i className="bi bi-check" />
                <h3>Product  Launches</h3>
              </div>
            </div>
            <div className="col-sm-12 d-flex justify-content-center">
              <div data-aos="fade-up">
                <div className="text-center text-lg-start">
                  <a href="https://app.redtie.co/"  className="btn-get-started-section d-inline-flex">
                    <span>Try Now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>        
 
    )
}

export default TwoWayMessage