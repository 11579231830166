import "./assets/css/style.css";
import "./assets/css/custom-style.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Features from "./components/Features";
import Pricing from "./components/Pricing";
import ContactUs from "./components/ContactUs";
import Faq from "./components/Faq";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsandConditions from "./components/TermsandConditions";
import Home from "./components/Home";

function App() {
  let pattern = /blog/i;
  console.log("window.location.pathname", window.location.pathname);
  if (window.location.pathname === "/appsumo" || window.location.pathname === "/appsumo/") {
    window.location.href = "https://appsumo.getredtie.com/";
  } else if (
    window.location.pathname === "" ||
    window.location.pathname === "/" ||
    window.location.pathname === "/features" ||
    window.location.pathname === "/pricing" ||
    window.location.pathname === "/contactus" ||
    window.location.pathname === "/faq" ||
    window.location.pathname === "/privacy-policy" ||
    window.location.pathname === "/terms-conditions"
  ) {
    console.log(window.location.pathname); //yields: "/js" (where snippets run)
    console.log(window.location.href);
  } else if (window.location.pathname.match(pattern)) {
    let url = window.location.href;
    let lasturi = url.substring(url.lastIndexOf("/") + 1);
    if (lasturi === "blog") {
      window.location.href = "https://redtie.medium.com/";
    } else {
      window.location.href = "https://redtie.medium.com/" + lasturi;
    }
  } else {
    window.location.href = "https://getredtie.com/";
  }
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsandConditions />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
    </>
  );
}

export default App;
