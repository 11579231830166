export const home = [
    {
      type:'title',
      inside:'Messaging gateway for everything'
    },
    {
      type: 'meta',
      name:'description',
      content: 'The Business Text messaging solution, easy to use and affordable. Share large resolution images, videos, audios, documents such as Word/PDF, meeting invites, signature requests and NFTs over a Text Message with Redtie - Text messaging gateway.'
    },
    {
      type: 'meta',
      name:'keywords',
      content: 'Text Message Marketing, Text messaging, File sharing, Business Texting, SMS Marketing, Auto Responder, Lead Generation, Customer Support, Customer Engagement'
    },
]

export let features = [
  {
    type:'title',
    inside:'Features | Redtie | SMS/Text Message Marketing'
  },
  {
    type: 'meta',
    name:'description',
    content: 'Powerful features to drum-up more leads, better Engagement and Conversion with powerful AI driven messaging Services.'
  },
  {
    type: 'meta',
    name:'keywords',
    content: 'Text Messaging App, Automation, Text  Marketing, File sharing, Business Texting, Bulk SMS, Auto Responder, Lead Generation, Customer Engagement, SMS Features'
  },
]

export let pricing = [
  {
    type:'title',
    inside:'Pricing | Share Files via SMS | Redtie'
  },
  {
    type: 'meta',
    name:'description',
    content: 'The affordable messaging solution in USA, easy to use and 100% refund if not satisfied - Redtie - The Business Text Messaging App'
  },
  {
    type: 'meta',
    name:'keywords',
    content: 'Text Messaging App, Automation, Text  Marketing, File sharing, Business Texting, Bulk SMS, Auto Responder, Lead Generation, Customer Engagement, SMS Features'
  },
]

export const faq = [
    {
      type:'title',
      inside:'Frequently Asked Questions | Redtie Knowledge Base'
    },
    {
      type: 'meta',
      name:'description',
      content: 'FAQs for Redtie. What is Redtie? How to send text messages with attachments? What is SMS? What is a short code? How do keywords work? How many characters may I include in a text message?'
    },
    {
      type: 'meta',
      name:'keywords',
      content: 'Frequently Asked Question, file attachments, how to send text message, file formats'
    },
]

export const contact = [
    {
      type:'title',
      inside:'Contact Us | Redtie'
    },
    {
      type: 'meta',
      name:'description',
      content: 'Have questions about SMS marketing? Ready to try Redtie? Contact us or send a message and we will be happy to tell you more!'
    },
    {
      type: 'meta',
      name:'keywords',
      content: 'Contact us Redtie, sms gateway, autoresponders, sms short code, texting services for businesses'
    },
]

export const termsandcon = [
    {
      type:'title',
      inside:'Terms and conditions | Redtie'
    },
    {
      type: 'meta',
      name:'description',
      content: 'Redtie attempts to make the messaging extended to handle every digital asset attached to the message. Also Chrome plugin and Gmail utilities to improve the efficiency of using the messaging.'
    },
    {
      type: 'meta',
      name:'keywords',
      content: 'mass texting, automated text messaging, shortcode texting, mass text messaging, sms gateway, autoresponders'
    },
]