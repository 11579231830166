import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { termsandcon } from "../includes/HeaderTags"


const TermsandConditions = () => {
  useEffect(() => {
    termsandcon.forEach(item => {
      if(item.type === 'title'){
        document.title = item.inside
      } else if(item.type === 'meta'){
        const tags = document.createElement('meta');
        tags.name = item.name;
        tags.content = item.content;
        document.getElementsByTagName('head')[0].appendChild(tags);
      } 
    })
  },[])
  return (
    <>
      <Header />
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <header className="section-header mt-5 pt-5">
            <h2>Terms and Conditions</h2>
            {/* <p>Frequently Asked Questions</p> */}
          </header>
          <div className="row">
            <div className="col-lg-12">
              <div>
                <p>
                  Please check these Terms and Conditions frequently, as we may
                  from time to time unilaterally amend the Terms and Conditions
                  by posting revised language. The most up-to-date version of
                  these Terms and Conditions will always be available for your
                  review. Amendments will become effective at the time they are
                  posted. Your continued use of Redtie after amendments are
                  posted will constitute your acceptance of such amendments.
                </p>
                <p>
                  If you are using Redtie on behalf of a company or other
                  entity, then “Client” or “you” means that entity, and you are
                  binding that entity to the Redtie Terms and Conditions,
                  Privacy Policy and Business Associate Agreement. And you have
                  the legal power and authority to enter into this Agreement, if
                  the Client is an entity, this Agreement is entered into by an
                  employee or agent with all necessary authority to bind that
                  entity to this Agreement.
                </p>
                <h4>
                  <strong>1. DEFINITIONS</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  1.1. “Confidential Information” means but not limited to;
                  code, intentional property, product functionality, technical
                  and financial, operational, or other information exchanged
                  under this Agreement.&nbsp;&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  1.2. “HIPAA”, Health Information Technology for Economic and
                  Clinical Health Act of 2009 (the “HITECH Act”), the
                  Administrative Simplification section of the Health Insurance
                  Portability and Accountability Act of 1996, 42 U.S.C. §1320d
                  through d-8, as amended from time to time, and the
                  requirements of regulations under the HITECH Act or
                  HIPAA.&nbsp;&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  1.3. “Business Associate Agreement” (“BAA”), a written
                  agreement between a covered entity and a business associate
                  specified at 45 CFR 164.504(e).
                </p>
                <p style={{ paddingLeft: 40 }}>
                  1.4. “Protected Health Information” (“PHI”) meaning the same
                  defined in HIPAA.
                </p>
                <h4>
                  2. <strong>SERVICES</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  2.1. Redtie is a proprietary multi-product platform that
                  includes services like but not limited to; texting secure
                  attachments, ability to set appointments, archiving, automated
                  responses, sending and receiving voice messaging, arrange
                  contacts by custom tags, encrypted transmission of data,
                  storing the data in a secure cloud, multi user access,
                  creating video and voice messages on the fly, read receipt,
                  metrics to determine campaign effectiveness, creating of
                  customer QR codes that generate a text response.&nbsp; Client
                  can purchase Services as described under “Pricing”at&nbsp;
                  <a href="https://www.getredtie.com/pricing/">
                    https://www.getredtie.com/pricing/
                  </a>
                  .&nbsp; Redtie is not&nbsp;obligated to provide any services
                  or perform tasks not specified in its service
                  offering.&nbsp;&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  2.2.&nbsp;Changes in Services.&nbsp;Redtie reserves the right
                  to modify or discontinue Services at any time, alter the
                  offering by adding, limiting or discontinuing pricing plans
                  temporarily or permanently.&nbsp; Redtie also reserves the
                  right to replace Services with equivalent functionally.
                </p>
                <h4>
                  <strong>3. USE RIGHTS; RESTRICTIONS</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  3.1.&nbsp;Account Registration.&nbsp;Client, must register for
                  Redtie to access the Services. Account information must be
                  accurate, current and complete.&nbsp; Redtie will not be
                  liable for any loss or damage arising from unauthorized use of
                  Client’s account.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  3.2.&nbsp;Authorized Users.&nbsp;Client is responsible and
                  liable for all Authorized Users’ use, access and compliance
                  with the terms and conditions. Client is responsible for
                  creating user IDs, passwords. Client is responsible for
                  determining Users access and restricting and/or terminating
                  the rights, as needed. Redtie may, at its discretion, suspend
                  User’s access. Client is solely responsible for ensuring that
                  user IDs, passwords are kept confidential and not shared.
                  Client is responsible for complying, and ensuring its Users
                  comply, with all laws. Client will notify Redtie within
                  twenty-four (24) hours of any breach of security or
                  unauthorized use.&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  3.3.&nbsp;Use by Others.&nbsp;By agreeing to these Terms,
                  Client warrants that its contractors and employees are
                  authorized to use the Services on its behalf (“Authorized
                  Users”): (a) are over 18 years old; (b) comply with all
                  applicable laws. Agreement. Authorized Users are subject to
                  all applicable terms and conditions of this Agreement.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  3.4.&nbsp;Redtie Restrictions.&nbsp;Client will not allow any
                  third party to: (a), copy, transfer, sublicense or provide
                  access to the Redtie Technology to a third party (b).
                  incorporate the Redtie technology, into, or use it with any
                  site, product or service; (c) publicly disseminate information
                  regarding the performance of Redtie (d) reverse engineer,
                  decompile, translate or obtain or derive the source code,
                  ideas, algorithms, formats or non-public APIs, (e) break or
                  circumvent any security measures.&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  3.5.&nbsp;Use by Customer.&nbsp;Any one that is a client or
                  customer, or a potential customer who accesses or uses Redtie
                  are subject to all policies and terms, including Redtie’s
                  right to remove or disable access to any one that violates
                  Redite’s policies.&nbsp;
                </p>
                <h4>
                  <strong>4. INFORMATION COLLECTION</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  4.1.&nbsp;Client Data.&nbsp;Client will retain all rights to
                  text messages, images and or other content or documents Client
                  submits for use with the Services. If Client is a Covered
                  Entity or a Business Associate, “Client Data” including PHI.
                  “Third-Party Content” means content, data or other materials
                  that Client provides to the Redtie Services from third-party
                  data providers.&nbsp; Client grants Redite a non-exclusive,
                  worldwide, royalty-free right and license to collect, use,
                  copy, store, transmit, modify, solely to the extent necessary
                  to provide the Services and related services to Client. Client
                  grants Redtie to use and disclose Client and Customer Data
                  necessary to (1) provide the Services consistent with this
                  Agreement and Privacy Policy, including detecting,
                  investigating, and preventing security incidents, spam, fraud,
                  or unlawful use of the Services, and (2) respond to Client’s
                  inquiries or any technical problems and ensure the Services
                  are working properly.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  4.2.&nbsp;Aggregate Data.&nbsp;Redtie has the right to create
                  usage data from Client use and may aggregate anonymized Client
                  Data. Redtie may use Aggregate/Anonymous Data for any business
                  purpose during or after the term of this Agreement.
                  Aggregate/Anonymous Data will not identify Client or its
                  Customers, or would violate applicable laws.&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  4.3.&nbsp;Monitoring.&nbsp;Client agrees that Redtie, and any
                  third-party platform(s) Client uses to access the Services,
                  may be monitored and used to analyze Client Data or Customer
                  Data to improve the Website, Services or third-party
                  platforms.&nbsp;&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  4.4.&nbsp;Security.&nbsp;Redtie agrees to maintain physical,
                  technical and organizational measures designed to secure
                  systems from unauthorized access or use. If Client is a
                  Business Associate or Covered Entity, Client agrees to the
                  terms of Redtie’s BAA, which may be amended from time to time,
                  to secure and utilize PHI in accordance with the BAA. Redtie
                  is not liable or responsible for any Client Data.
                </p>
                <h4>
                  <strong>5. CLIENT OBLIGATIONS</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  5.1.&nbsp;Use of the Services. Client understands and agrees
                  that Redtie Services are intended to allow Client to send
                  electronic communication, including but not limited to text
                  messages, only to Client’s Customers who have consented to the
                  receipt of such communications and are provided with necessary
                  notices in accordance with applicable law and regulations.
                  Send Marketing messages through Redite only in compliance with
                  all local, state, national and international laws, regulations
                  and industry-specific best practices.&nbsp; Client agrees and
                  acknowledges that Client is solely responsible for its
                  compliance with applicable law and regulations and must not
                  rely on the Services for any such compliance. Redtie reserves
                  the right to suspend or terminate Client’s access to the
                  Services if Redtie believes, in its discretion, that Client
                  has violated this Section.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  5.2.&nbsp;Warranty.&nbsp;Client warrants that it is in full
                  compliance with all applicable laws, rules and regulations and
                  that Client will not use Redtie in a way to violate or cause
                  Redtie to violate any obligation or laws, rules or
                  regulations.&nbsp; Including but not limited to the Federal
                  Trade Commission’s Telemarketing Sales Rule, the Telephone
                  Consumer Protection Act of 1991, the Health Insurance
                  Portability and Accountability Act of 1996, the
                  Gramm-Leach-Bliley Act of 1999, the CAN-SPAM Act, Do Not Call
                  rules and prohibitions.&nbsp; Client also warrants and
                  represents that: (a) Client has sole ownership of any Client
                  Data or has legal rights to provide the data. Client Data and
                  Redtie’s use will not violate third-party rights; (b) Redtie’s
                  possession and/or use of Client Data will not violate any
                  contract, statute, or regulation; (c) any Client Data Client
                  and/or an online review or ratings will be true and accurate,
                  and will only represent the goods and/or services that Client
                  provides; (d) Client and/or Client’s authorized
                  representative(s) will only use the Services for interaction
                  with actual Customers.
                </p>
                <h4>
                  <strong>6. AVAILABILITY OF SERVICES; SUPPORT</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  6.1.&nbsp;Availability.&nbsp; Scheduled maintenance and
                  unavailability caused by: (a) actions or omissions of Client;
                  (b) failures, errors or defects in the facilities, hardware,
                  software or network of Client; or (c) circumstances that are
                  beyond Redite’s reasonable control, the Services will be
                  available 99.0% of the time.&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  6.2&nbsp; Neither party will be liable for any delay or
                  failure to perform its obligations under this Agreement
                  (except payment obligations) if the delay or failure is due to
                  causes beyond its reasonable control, such as a strike,
                  blockade, war, act of terrorism, riot, natural disaster,
                  disruption in transportation systems, disruption of labor
                  force, national or state emergency, epidemic, pandemic,
                  communicable disease outbreak, failure or reduction of power
                  or telecommunications or data networks or services, or
                  government act or order.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  6.3.&nbsp;Support.&nbsp;Support is available through the
                  Website and through Chat on the Redtie Service. Additional
                  support may be available subject to payment of applicable fees
                  (if any).
                </p>
                <h4>
                  <strong>7. FEES AND PAYMENT</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  7.1.&nbsp;Fees.&nbsp;Pricing information is specified at&nbsp;
                  <a href="https://www.getredtie.com/pricing/">
                    https://www.getredtie.com/pricing/
                  </a>
                  .&nbsp; Services are provided on a subscription-basis
                  including automatically recurring payments for periodic
                  charges.&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  7.2.&nbsp;Payment of Fees.&nbsp;
                  <b>Needs to be updated with Redtie payment policy</b>
                </p>
                <p style={{ paddingLeft: 40 }}>
                  7.3.&nbsp;Taxes.&nbsp;Redtie’s Fees are exclusive of all
                  taxes, Client must pay any taxes or levies.&nbsp;&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  7.4.&nbsp;Fee Increase.&nbsp;Redtie reserves the right to
                  increase Fees for any Services, upon sixty (30) days’ prior
                  written notice, effective on the start date of your subsequent
                  Subscription Term.
                </p>
                <h4>
                  <strong>8. TERM AND TERMINATION</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  8.1.&nbsp;Term and Termination.&nbsp;This Agreement is
                  effective until the Subscription or terminated. Unless
                  otherwise stated in Client’s Subscription term is described
                  in&nbsp;
                  <a href="https://www.getredtie.com/pricing/">
                    https://www.getredtie.com/pricing/
                  </a>
                  &nbsp;and will be automatically renewed until terminated by
                  the Client or Redtie.&nbsp;&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  8.2.&nbsp;Termination for Cause. Either party may terminate
                  the Service, if the other party: (a) fails to mitigate any
                  material breach of this Agreement within five (5) days after
                  notification detailing the breach. Redtie may also terminate
                  this Agreement for repeated violations or a breach of the
                  Clients’ BAA.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  8.3.&nbsp;Effect of Termination.&nbsp;Upon expiration or
                  termination of Redtie Client’s right to access any data will
                  cease, and unless otherwise precluded by a BAA.&nbsp; Redtie
                  may delete any such data in its possession at any time.
                </p>
                <h4>
                  <strong>9. TECHNOLOGY</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  9.1.&nbsp;Ownership and Updates.&nbsp;By accepting this
                  Agreement, Client acknowledges that it has a limited right to
                  use the Services with no ownership rights. Client agrees that
                  Redtie retains all rights, title and interest and intellectual
                  property rights in and to all products like but not limited
                  to, documentation, software, technology, code, logos,
                  trademarks, and templates.&nbsp; Redtie exclusively owns all
                  rights, title, and interest in and to Redtie’s Confidential
                  Information and any other data.&nbsp; Client acknowledges that
                  it has no right to a copy of the software and that Redtie at
                  its option may make updates, bug fixes, modifications or
                  improvements to the Services as needed.&nbsp;&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  9.2.&nbsp;Feedback.&nbsp;If Client provides any suggestions,
                  comments, ideas or other feedback or related materials to
                  Redtie, Client grants Redtie a right and license to use such
                  Feedback in any manner without any obligation, or restriction
                  based on intellectual property rights.
                </p>
                <h4>
                  <strong>10. INDEMNIFICATION</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  Indemnification by Client.&nbsp;Client will indemnify and hold
                  harmless Redtie its officers, directors, employees,
                  consultants, affiliates, subsidiaries and agents from and
                  against any third-party claims and related costs, damages,
                  liabilities and expenses arising from or pertaining to: (a)
                  your unauthorized use of, or misuse of, the Services; (b) your
                  violation of any applicable law or third party right,
                  including any intellectual property right, confidentiality,
                  other property, or privacy right; (c) any dispute or issue
                  between you and any third party; (d) any demand, dispute or
                  issue between you and your Payment Processor; (e) breach or
                  alleged breach of this Agreement; (f) any claim of a
                  governmental entity or other party that you have violated any
                  law, rule, or regulation; or, (g) alleged breach of Client’s
                  obligations in the BAA. Client also agrees to defend the
                  Redtie against these claims. If and when Redtie assumes
                  exclusive defense of such claims, Client agrees to cooperate
                  with our defense of any such claims. Client must not settle
                  any claim without Redtie’s prior written consent if the
                  settlement does not fully release Redtie from liability or
                  require Redtie to admit fault, pay any amounts or take or
                  refrain from taking any action.
                </p>
                <h4>
                  <strong>11.&nbsp;</strong>
                  <strong>DISCLAIMERS</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  11.1 NOTE: BY ACCESSING&nbsp;
                  <a
                    href="https://www.getredtie.com/"
                    rel="nofollow noopener"
                  >
                    WWW.GETREDTIE.COM
                  </a>
                  &nbsp;AND OR USING THE REDTIE SERVICES&nbsp; YOU WILL BE
                  ACCEPTING THESE TERMS AND CONDITIONS AND THE REFERENCED
                  REQUIREMENTS OF REDTIE’S PRIVACY POLICIES. IF YOU DO NOT
                  ACCEPT THESE TERMS, DO NOT USE THIS WEBSITE.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  11.2 WE MAY AT ANY TIME MODIFY OR DISCONTINUE ANY ASPECT OR
                  COMPONENT OF REDTIE. IF YOU BREACH ANY OF THESE TERMS OF USE,
                  WE CAN TERMINATE YOUR RIGHT TO USE THE REDITE.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  11.3. EXCEPT AS PROVIDED EXPRESSLY HEREIN, ALL REDTIE
                  TECHNOLOGY AND RELATED SERVICES, MATERIALS AND CONTENT
                  AVAILABLE THROUGH THE REDTIE TECHNOLOGY ARE PROVIDED “AS IS”
                  AND ON AN “AS AVAILABLE” BASIS. NEITHER REDTIE NOR ITS
                  SUPPLIERS MAKE ANY WARRANTIES, EXPRESS OR IMPLIED, STATUTORY
                  OR OTHERWISE.&nbsp; REDTIE WILL NOT BE RESPONSIBLE OR LIABLE
                  IN ANY MANNER FOR ANY CLIENT PROPERTIES, THIRD-PARTY PRODUCTS,
                  THIRD-PARTY CONTENT, OR NON-REDTIE SERVICES.&nbsp;
                </p>
                <h4>
                  <strong>12.&nbsp;</strong>
                  <strong>LIMITATIONS OF LIABILITY</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  IN NO EVENT WILL REDTIE OR ITS SUPPLIERS BE LIABLE FOR ANY
                  LOSS OF USE, LOST OR INACCURATE DATA, INTERRUPTION OF
                  BUSINESS, LOST PROFITS, COSTS OF DELAY, REPUTATIONAL HARM, OR
                  DAMAGES OF ANY KIND.&nbsp; IN NO EVENT WILL REDTIE OR ITS
                  SUPPLIERS’ TOTAL LIABILITY EXCEED IN AGGREGATE THE AMOUNT
                  ACTUALLY PAID BY CLIENT TO REDTIE FOR THE APPLICABLE SERVICE.
                </p>
                <h4>
                  <strong>13.&nbsp;</strong>
                  <strong>DISPUTE RESOLUTION</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  13.1.3.&nbsp;Location.&nbsp;Mediation will occur in Marin
                  County, CA.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  13.1.1.&nbsp;Notice.&nbsp;Mediation must be conducted within
                  sixty (60) days from a party receiving written notice of
                  Claims from a complaining party. The notice must contain a
                  detailed description of the nature of the Claims and the
                  requested relief sought.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  13.1.2.&nbsp;Mediator Selection and Mediator Fees. A neutral
                  mediator will be selected as mutually agreed upon by the
                  parties. The mediator’s fees and costs will be paid to the
                  mediator at the end of mediation, with both parties equally
                  sharing the mediation costs and paying their own legal fees
                  and costs.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  13.2.&nbsp;Judicial Action. If the parties are unable to
                  resolve the Claim pursuant to the mandatory mediation (or if
                  one of the parties refuses to participate in the mandatory
                  mediation or fails to respond to a complaining party’s request
                  for mediation), the parties may subsequently file a judicial
                  action.
                </p>
                <h4>
                  <strong>14.&nbsp;</strong>
                  <strong>GENERAL</strong>
                </h4>
                <p style={{ paddingLeft: 40 }}>
                  14.1.&nbsp;Assignment.&nbsp;Neither party may assign this
                  Agreement without the advance written consent of the other
                  party, except that Redtie may assign this Agreement without
                  consent to an affiliate or in connection with a merger,
                  reorganization, acquisition or other transfer of all or
                  substantially all of its assets or voting securities.&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  14.2.&nbsp;Notices.&nbsp;Any notice or communication to Redtie
                  under this Agreement must be in writing. Client must send any
                  notices under this Agreement to Redtie and include “Attn.
                  Legal Department” in the subject line. Redtie may send notices
                  to the text address on Client’s account Redtie may provide
                  notices regarding the Services or other business-related
                  notices on the Redtie website or text them to the
                  Client.&nbsp; Each party hereby consents to receipt of
                  electronic notices and agrees that notices, we send to you
                  electronically will satisfy any legal communication
                  requirements.&nbsp;
                </p>
                <p style={{ paddingLeft: 40 }}>
                  14.3.&nbsp;Publicity.&nbsp;Unless otherwise specified, Redtie
                  may use Client’s name, logo and marks to identify Client as a
                  Redtie Client on Redtie’s website and other marketing
                  materials.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  14.4.&nbsp;Attorneys’ Fees and Costs.&nbsp;The substantially
                  prevailing party in any action to enforce this Agreement will
                  be entitled to recover its reasonable attorneys’ fees and
                  costs for the action.
                </p>
                <p style={{ paddingLeft: 40 }}>
                  14.5.&nbsp;Governing Law; Jurisdiction and
                  Venue.&nbsp;6.4&nbsp;Governing Law/Jurisdiction.&nbsp; This
                  Agreement and all matters arising out of or relating to this
                  Agreement shall be governed by the internal laws of the State
                  of California without giving effect to any choice of law
                  rule.&nbsp; In the event of any controversy, claim, or dispute
                  between the parties arising out of or relating to this
                  Agreement, such controversy, claim, or dispute shall be tried
                  solely in a state or federal court for Marin County,
                  California, and the parties hereby irrevocably consent to the
                  jurisdiction and venue of such courts.&nbsp;
                </p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TermsandConditions;
