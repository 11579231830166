import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { pricing } from "../includes/HeaderTags";
import $ from "jquery";

const Pricing = () => {
  const togglechange = (values) => {
    if (values === "1") {
      $(".btn_price").removeClass("acctive_btn");
      $(".annual_cls").addClass("acctive_btn");
      $(".annual_data").show();
      $(".monthly_data").hide();
    } else {
      $(".btn_price").removeClass("acctive_btn");
      $(".monthly_cls").addClass("acctive_btn");
      $(".annual_data").hide();
      $(".monthly_data").show();
    }
  };

  useEffect(() => {
    pricing.forEach((item) => {
      if (item.type === "title") {
        document.title = item.inside;
      } else if (item.type === "meta") {
        const tags = document.createElement("meta");
        tags.name = item.name;
        tags.content = item.content;
        document.getElementsByTagName("head")[0].appendChild(tags);
      }
    }, []);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <main id="main">
        <section id="pricing" className="pricing">
          <div className="container" data-aos="fade-up">
            <header className="section-header mt-5  pt-5">
              <h2>Pricing</h2>
              <p className="mb-3">
                Share your files, Meeting or Signature requests, NFTs quick and
                secure over text message now. Choose a plan that fits you best.
              </p>
              <div className="d-flex justify-content-center">
                <div className="price_btn_div">
                  <button
                    className="btn_price acctive_btn annual_cls"
                    onClick={() => togglechange("1")}
                  >
                    Annually
                  </button>
                  <button
                    className="btn_price monthly_cls"
                    onClick={() => togglechange("2")}
                  >
                    Monthly
                  </button>
                </div>
              </div>
            </header>
            <div
              className="row gy-4 justify-content-center annual_data"
              data-aos="fade-left"
            >
              <p className="mb-3 mt-3 text-center">
                Pay annually and <strong> SAVE UPTO 45 %</strong>
              </p>
              {/* <div
                className="col-lg-3 col-md-6"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
              <div className="box">
                <h3 style={{color: '#07d5c0'}} className="d-flex align-items-center justify-content-center">Free</h3>
                <div className="price"><sup>$</sup>0<span> / Month</span></div>
                <img
                    src={require("../assets/img/pricing-free.png")}
                    className="img-fluid"
                    alt=""
                  />
                <ul>
                  <li>5 Contacts</li>
                  <li>50 Messages</li>
                  <li>Attachments</li>
                  <li className="na">Team Members</li>
                  <li className="na">Message Archiving</li>
                  <li className="na">Two-Way Messaging</li>
                  <li className="na">Dedicated Phone Number</li>
                  <li className="na">Summary Alert</li>
                  <li className="na">Teams and Workspaces</li>
                  <li className="na">Integrations</li>
                  <li>API (Valid for 1 Month)</li>
                  <li className="na">Webhooks</li>
                </ul>
                <a href="https://application.redtie.co/" className="btn btn-buy">Try Now </a>
              </div>
            </div> */}

              {/* <div className="col-lg-3 col-md-6">
              <div className="box">
                <h3 style={{color: '#65c600'}} className="d-flex align-items-center justify-content-center">Paid</h3>
                <div className="price"><sup>$</sup>60<span> / Year</span></div>
                <img
                    src={require("../assets/img/pricing-starter.png")}
                    className="img-fluid"
                    alt=""
                  />
                <ul>
                  <li>Unlimited Contacts</li>
                  <li>300 Messages</li>
                  <li>Attachments</li>
                  <li>5 Team Members</li>
                  <li>Message Archiving</li>
                  <li>Two-Way Messaging</li>
                  <li>Dedicated Phone Number</li>
                  <li>Summary Alert</li>
                  <li>Teams and Workspaces</li>
                  <li>Integrations</li>
                  <li>API</li>
                  <li>Webhooks</li>
                </ul>
                <a href="https://application.redtie.co/" className="btn btn-buy">Buy Now </a>
              </div>
            </div> */}
              <div className="col-lg-3 col-md-6">
                <div className="box">
                  <h3
                    style={{ color: "#ff901c" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    Bring your own messaging service provider
                  </h3>
                  <div className="price">
                    <sup>$</sup>500<span> / Year</span>
                  </div>
                  <img
                    src={require("../assets/img/pricing-business.png")}
                    className="img-fluid"
                    alt=""
                  />
                  <ul>
                    <li>
                      {" "}
                      <img
                        src={require("../assets/img/plivo_logo.png")}
                        className="img-fluid plan_byon_img"
                        alt=""
                      />{" "}
                    </li>
                    <li>
                      <img
                        src={require("../assets/img/twilio_logo.png")}
                        className="img-fluid plan_byon_img"
                        alt=""
                      />
                    </li>
                    <li> Redtie doesn't charge for messages. </li>
                    <li>
                      {" "}
                      You just pay the monthly rental for using Redtie's
                      services.{" "}
                    </li>
                    <li>
                      {" "}
                      The messaging cost is payable to the messaging service
                      provider that you opt-in here.{" "}
                    </li>
                  </ul>
                  <a
                    href="https://application.redtie.co/"
                    className="btn btn-buy"
                  >
                    Buy Now{" "}
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="box">
                  <h3
                    style={{ color: "#ff0071" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    Enterprise
                  </h3>

                  <a
                    href="https://getredtie.com/contactus"
                    className="btn btn-buy"
                  >
                    Contact us{" "}
                  </a>
                  <img
                    src={require("../assets/img/pricing-ultimate.png")}
                    className="img-fluid"
                    alt=""
                  />
                  <ul className="enterprise_sec_ht">
                    <li>
                      {" "}
                      You get everything as in Paid but at a lower price based
                      on the volume of outbound and inbound messages that your
                      business requires.
                    </li>
                    <li> Contact us for more information. </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              className="row gy-4 justify-content-center monthly_data"
              data-aos="fade-left"
              style={{ display: "none" }}
            >
              {/* <div
                className="col-lg-3 col-md-6"
                data-aos="zoom-in"
                data-aos-delay={100}
              >
                <div className="box">
                <h3 style={{color: '#07d5c0'}} className="d-flex align-items-center justify-content-center">Free</h3>
                <div className="price"><sup>$</sup>0<span> / Month</span></div>
                <img
                    src={require("../assets/img/pricing-free.png")}
                    className="img-fluid"
                    alt=""
                  />
                <ul>
                  <li>5 Contacts</li>
                  <li>50 Messages</li>
                  <li>Attachments</li>
                  <li className="na">Team Members</li>
                  <li className="na">Message Archiving</li>
                  <li className="na">Two-Way Messaging</li>
                  <li className="na">Dedicated Phone Number</li>
                  <li className="na">Summary Alert</li>
                  <li className="na">Teams and Workspaces</li>
                  <li className="na">Integrations</li>
                  <li>API (Valid for 1 Month)</li>
                  <li className="na">Webhooks</li>
                </ul>
                <a href="https://application.redtie.co/" className="btn btn-buy">Try Now </a>
              </div>
            </div> */}

              {/* <div className="col-lg-3 col-md-6">
              <div className="box">
                <h3 style={{color: '#65c600'}} className="d-flex align-items-center justify-content-center">Paid</h3>
                <div className="price"><sup>$</sup>9<span> / Month</span></div>
                <img
                    src={require("../assets/img/pricing-starter.png")}
                    className="img-fluid"
                    alt=""
                  />
                <ul>
                  <li>Unlimited Contacts</li>
                  <li>300 Messages</li>
                  <li>Attachments</li>
                  <li>5 Team Members</li>
                  <li>Message Archiving</li>
                  <li>Two-Way Messaging</li>
                  <li>Dedicated Phone Number</li>
                  <li>Summary Alert</li>
                  <li>Teams and Workspaces</li>
                  <li>Integrations</li>
                  <li>API</li>
                  <li>Webhooks</li>
                </ul>
                <a href="https://application.redtie.co/" className="btn btn-buy">Buy Now </a>
              </div>
            </div> */}
              <div className="col-lg-3 col-md-6">
                <div className="box">
                  <h3
                    style={{ color: "#ff901c" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    Bring your own messaging service provider
                  </h3>
                  <div className="price">
                    <sup>$</sup>50<span> / Month</span>
                  </div>
                  <img
                    src={require("../assets/img/pricing-business.png")}
                    className="img-fluid"
                    alt=""
                  />
                  <ul>
                    <li>
                      {" "}
                      <img
                        src={require("../assets/img/plivo_logo.png")}
                        className="img-fluid plan_byon_img"
                        alt=""
                      />{" "}
                    </li>
                    <li>
                      {" "}
                      <img
                        src={require("../assets/img/twilio_logo.png")}
                        className="img-fluid plan_byon_img"
                        alt=""
                      />{" "}
                    </li>
                    <li> Redtie doesn't charge for messages. </li>
                    <li>
                      {" "}
                      You just pay the monthly rental for using Redtie's
                      services.{" "}
                    </li>
                    <li>
                      {" "}
                      The messaging cost is payable to the messaging service
                      provider that you opt-in here.{" "}
                    </li>
                  </ul>
                  <a
                    href="https://application.redtie.co/"
                    className="btn btn-buy"
                  >
                    Buy Now{" "}
                  </a>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="box">
                  <h3
                    style={{ color: "#ff0071" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    Enterprise
                  </h3>
                  <a
                    href="https://getredtie.com/contactus"
                    className="btn btn-buy"
                  >
                    Contact us{" "}
                  </a>
                  <img
                    src={require("../assets/img/pricing-ultimate.png")}
                    className="img-fluid"
                    alt=""
                  />
                  <ul className="enterprise_sec_ht">
                    <li>
                      {" "}
                      You get everything as in Paid but at a lower price based
                      on the volume of outbound and inbound messages that your
                      business requires.
                    </li>
                    <li> Contact us for more information. </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default Pricing;
