import React, { useEffect } from "react";
import Header from "./Header"
import Banner from "./Banner"
import Features from "./Features"
import HowItWorks from "./HowItWorks";
import TwoWayMessage from "./TwoWayMessage";
import Footer from "./Footer";
import Integrations from "./Integrations";
import { home } from "../includes/HeaderTags"

function Home() {
  useEffect(() => {
    home.forEach(item => {
      if(item.type === 'title'){
        document.title = item.inside
      } else if(item.type === 'meta'){
        const tags = document.createElement('meta');
        tags.name = item.name;
        tags.content = item.content;
        document.getElementsByTagName('head')[0].appendChild(tags);
      } 
    }, [])
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Banner />
      {/* <Features /> */}
      <HowItWorks />
      <TwoWayMessage />
      <Integrations />
      <Footer />
    </>
  )
}

export default Home