import React from "react";

const Integrations = () => {
  return (
    <section id="team" className="team mb-5">
      <div className="container aos-init aos-animate" data-aos="fade-up">
      <header className="section-header">
          <h2 className="">Integrations</h2>
        </header>
        <div className="row gy-4 d-flex justify-content-center">
          <div
            className="col-lg-1 col-4 col-md-1 col-sm-2 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="member">
              <a href="https://redtie.medium.com/" >
                <div className="member-img" title="Google Calender">
                  <img
                    src={require("../assets/img/integration/redtie-google-calender-integration.png")}
                    className="img-fluid"
                    alt="redtie-google-calender"
                    title="Google Calender"
                  />
                </div>
              </a>
            </div>
          </div>
          <div
            className="col-lg-1 col-4 col-md-1 col-sm-2 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="member">
              <a href="https://redtie.medium.com/" >
                <div className="member-img" title="Slack">
                  <img
                    src={require("../assets/img/integration/redtie-slack-integration.png")}
                    className="img-fluid"
                    alt="redtie-slack"
                    title="Slack"
                  />
                </div>
              </a>
            </div>
          </div>
          <div
            className="col-lg-1 col-4 col-md-1 col-sm-2 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="member">
              <a href="https://redtie.medium.com/" >
                <div className="member-img" title="Zapier">
                  <img
                    src={require("../assets/img/integration/redtie-zapier-integration.png")}
                    className="img-fluid"
                    alt="redtie-zapier"
                    title="Zapier"
                  />
                </div>
              </a>
            </div>
          </div>
          {/* <div className="col-lg-1 col-4 col-md-1 col-sm-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
                        <div className="member">
                            <div className="member-img" title="Mailgun">
                                <img src={require("../assets/img/integration/redtie-mailgun-integration.png")} className="img-fluid" alt="redtie-mailgun" title="Mailgun" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-4 col-md-1 col-sm-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
                        <div className="member">
                            <div className="member-img" title="Stripe">
                                <img src={require("../assets/img/integration/redtie-stripe-integration.png")} className="img-fluid" alt="redtie-stripe" title="Stripe" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-4 col-md-1 col-sm-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
                        <div className="member">
                            <div className="member-img" title="Plivo">
                                <img src={require("../assets/img/integration/redtie-plivo-integration.png")} className="img-fluid" alt="redtie-plivo" title="Plivo" />
                            </div>
                        </div>
                    </div> */}
          <div
            className="col-lg-1 col-4 col-md-1 col-sm-2 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="member">
              <a href="https://redtie.medium.com/" >
                <div className="member-img" title="Gmail">
                  <img
                    src={require("../assets/img/integration/redtie-gmail-integration.png")}
                    className="img-fluid"
                    alt="redtie-gmail"
                    title="Gmail"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Integrations;
